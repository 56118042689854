@font-face {
  font-family: "Andika";
  src: url(/fonts/Andika-R.ttf);
}

@font-face {
  font-family: "AndikaNewBasic";
  src: url(/fonts/AndikaNewBasic-R.ttf);
}

@font-face {
  font-family: "AndikaNewBasic";
  font-weight: bold;
  src: url(/fonts/AndikaNewBasic-B.ttf);
}

@font-face {
  font-family: "CharisSIL-Literacy";
  src: url(/fonts/CharisSIL-Literacy-R.ttf);
}

@font-face {
  font-family: "CharisSIL-Literacy";
  font-weight: bold;
  src: url(/fonts/CharisSIL-Literacy-B.ttf);
}

div.compChart {
  font-family: "AndikaNewBasic";
  padding: 0.25em;
}

div.compChart input,
div.compChart textarea {
  font-size: inherit;
  text-align: inherit;
  font-family: inherit;
}

div.compChart textarea {
  width: 100%;
  height: 100%;
}

div.alphaTitle {
  margin: 0.1em 0 0.1em;
}

div.alphaSubtitle {
  margin: 0 0 0.4em;
}

div.alphaTableContainer {
  flex-grow: 1;
}

div.alphatable {
  width: 100%;
}

div.alphasummary {
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  flex-grow: 1;
  flex-shrink: 1;
}

div.alpharow {
  flex-direction: row;
  align-items: stretch;
}


div.alphacell {
  padding: 0.5em;
  box-sizing: border-box;
}

div.edit-alphacell:hover,
div.edit-alphacell[data-selected="true"] {
  -webkit-box-shadow: inset 0px 0px 8px 0px rgba(24, 146, 239, 1);
  -moz-box-shadow: inset 0px 0px 8px 0px rgba(24, 146, 239, 1);
  box-shadow: inset 0px 0px 8px 0px rgba(24, 146, 239, 1);
  cursor: pointer;
}

div.edit-alphacell:hover div.letter,
div.edit-alphacell[data-selected="true"] div.letter {
  color: #1892ef;
}

div.alphacell div.letter {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: baseline;
}

div.alphacell img {
  /* width: 80%; */
  margin: auto;
  display: block;
}

div.alphacell input[type="text"] {
  text-align: center;
  width: 80%;
  align-self: center;
}

div.alphacell input[type="text"]:focus::placeholder {
  color: #00000000;
}

div.alphacell .exampleWord {
  text-align: center;
  display: block;
}

div.drop-zone-root {
  position: relative;
}

div.edit-alpha-image[data-image-set="true"] p.drop-zone {
  display: none;
}

div.edit-alpha-image[data-image-set="true"]:hover p.drop-zone {
  display: block;
  position: absolute;
  bottom: 0;
  width: 80%
}

div.edit-alpha-image[data-image-set="true"]:hover img {
  /* display: none; */
  opacity: 0.2;
  z-index: -10;
}

div.drop-zone-root:focus {
  outline: none;
}

.drop-zone {
  padding-top: calc(40% - 8px);
  padding-bottom: calc(40% - 8px);
  margin: 0 10%;
  text-align: center;
  color: #999;
  font-size: 12px;
  line-height: 1;
  border: 2px #999 dashed;
  cursor: pointer;
}

p.drop-zone.drop-zone-active,
p.drop-zone:hover {
  border-color: #1892ef;
  color: #1892ef;
}

div.alphafooter {
  width: 100%;
}
/* AlphabetsBrowser */

div.compAlphabetsBrowser .letterPicker {
    border: 1px #999 solid;
    border-radius: 8px;
    flex-direction: row;
    flex-wrap: wrap;
    width: fit-content;
    align-items: baseline;
    margin-bottom: 12px;
}

div.compAlphabetsBrowser .letterPicker button {
    background-color: initial;
    color: #1892ef;
    margin: 0;
    font-size: 1.4em;
}

div.compAlphabetsBrowser .letterPicker button.selected {
    color: black;
    border: 2px #2d9bf0 solid;
    margin: -2px
}

/* AlphabetsList */

ul.compAlphabetsList {
    font-size: 1.3em;
}

ul.compAlphabetsList li {
    margin: 4px 0;
}

ul.compAlphabetsList .username {
    font-size: 0.7em;
    color: #999;
    font-style: italic;
    margin-left: 0.7em;
}

/* AppBanners */

div.compAppBanners {
    position: sticky;
    top: 8px;
}

div.compAppBanners .banner {
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 4px;
    flex-direction: row;
    justify-content: space-between;
}

div.compAppBanners .successBanner {
    background-color: #159B22;
    color: #fff;
}

div.compAppBanners .errorBanner {
    background-color: #c9302c;
    color: #fff;
}

div.compAppBanners .errorBanner .message {
    margin-right: 12px;
}

div.compAppBanners .errorBanner a {
    color: #fff;
    font-weight: bold;
}

/* ColorInput */

div.compColorInput .color-picker-preview {
    height: 24px;
    width: 24px;
    border: 2px #999 solid;
    cursor: pointer;
    align-self: flex-end;
}

div.compSimpleColorInput {
    flex-direction: row;
    justify-content: flex-start;
}

div.compSimpleColorInput .color-demo {
    height: 8px;
    width: 8px;
    border: 1px solid #000;
    margin: 4px;
    align-self: center;
}

div.compSimpleColorInput input {
    text-align: end;
}

/* ChartToImageOptionsMenu */

div.compChartToImageOptionsMenu {
    padding: 8px;
}

div.compChartToImageOptionsMenu div.optMenuHeader {
    font-weight: bold;
    margin-bottom: 8px;
}

div.compChartToImageOptionsMenu table tr td {
    border: none;
}

div.compChartToImageOptionsMenu input[type="text"] {
    text-align: right;
}

div.compChartToImageOptionsMenu input[type="checkbox"] {
    margin-right: 8px;
}

div.compChartToImageOptionsMenu .bgColor {
    margin: 12px 0;
}

div.compChartToImageOptionsMenu .bgColor div[data-disabled="true"] {
    color: #aaa;
}

div.compChartToImageOptionsMenu .buttonRow {
    flex-direction: row;
    justify-content: space-between;
}

/* CopyAlphabetButton */

div.compCopyAlphabetButton table {
    width: max-content;
}

div.compCopyAlphabetButton td {
    padding: 2px 4px;
    cursor: pointer;
}

div.compCopyAlphabetButton td:hover {
    color: #1892ef;
}

/* CurrentUserPage */

div.compCurrentUserPage input {
    margin-bottom: 8px;
}

/* ExportChart */

div.compExportChart {
    height: 100vh;
    flex-direction: row;
    background-color: #999;
}

div.compExportChart .controls {
    padding: 12px;
    background-color: #1892ef;
    color: #fff;
    overflow-y: scroll;
}

div.compExportChart .controls a {
    color: #fff;
    text-decoration: underline;
}

div.compExportChart .controls button {
    color: #1892ef;
    background-color: #fff;
}

div.compExportChart .controls button:active {
    background-color: #eee;
}

div.compExportChart .controls button.big {
    margin-left: 0;
    margin-right: 0;
}

div.compExportChart .preview {
    flex-grow: 1;
}

div.compExportChart pre {
    margin: 0;
}

div.compExportChart div.compColorInput .color-picker-preview {
    align-self: flex-start;
}

/* GroupsList */

div.compGroupsIndex .rowDiv {
    flex-direction: row;
}

div.compGroupsIndex .rowDiv > div {
    flex: 1
}

/* Icon */
.compIcon {
    cursor: pointer;
}

.compIcon path {
    fill: #aaa;
}

.compIcon[data-disabled='true'] {
    cursor: inherit;
}

.compIcon[data-disabled='true'] path,
.compIcon.iconBlue[data-disabled='true']:hover path,
.compIcon.iconBlue[data-disabled='true']:hover:active path  {
    fill: #ddd;
}

.compIcon.iconBlue:hover path {
    fill: #1892ef;
}

.compIcon.iconBlue:hover:active path {
    fill: #1892efdd;
}

/* LocalePicker */

div.compLocalePicker {
    flex-direction: row;
    padding: 0 8px;
}

/* NavBar */

div.compNavBar .linkMenu a {
    padding: 0 16px 0 0;
}

div.compNavBar .compLoading {
    color: #333;
}

/* NumberPicker */

div.NumberPicker {
    display: inline-block;
}
  
div.NumberPicker input {
    font-size: 16px;
    text-align: end;
}
  
div.NumberPicker button {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 100%;
    padding: 0 0 2px;
    line-height: 1;
    margin: 2px;
}

/* OldApiError */
div.compOldApiError {
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
}

div.compOldApiError a {
    text-decoration: underline;
    font-weight: normal;
    padding-left: 20px;
}

/* OptionButton */

div.compOptionButton {
    display: block;
    background-color: #1892ef;
    border-radius: 8px;
    margin: 4px;
    position: relative;
}

div.compOptionButtonSimple {
    position: relative;
    display: block;
}

div.compOptionButtonSimple > button {
    margin: 4px 4px 0;
}

div.compOptionButton>button{
    background: none;
    margin: 0;
    border-radius: 0;
}

div.compOptionButton>button:active {
    background-color: #ffffff22;
}

div.compOptionButton>button:first-child {
    border-right: 1px solid #ddd;
}

div.compOptionButton div.contextMenu,
div.compOptionButtonSimple div.contextMenu {
    position: absolute;
    background-color: white;
    box-shadow: 5px 5px 20px #999;
    z-index: 100;
}

/* ProgressBar */

div.compProgressBar {
    width: 100%;
    background-color: #999;
    height: 12px;
    border-radius: 4px;
    overflow: hidden;
}

div.compProgressBar > div {
    background-color: #fff;
    height: 100%;
    border-radius: 4px;
}

div.compProgressBar > div.indeterminate {
    width: 20%;
    animation-duration: 1.5s;
    animation-name: flyAcross;
    animation-iteration-count: infinite;
}

@keyframes flyAcross {
    from {
        margin-left: -20%;
    }
    to {
        margin-left: 100%
    }
}

/* PublicHomePage */

div.compPublicHomePage {
    align-items: baseline;
}

div.compPublicHomePage > div {
    flex: 1;
    margin: 24px;
}

div.compPublicHomePage input {
    width: 100%;
}

/* Red X */

span.compRedX a {
    color: #c9302c;
    font-size: larger;
}

span.compRedX a:hover {
    font-weight: bold;
    text-decoration: none;
}

/* SearchTextInput */

div.compSearchTextInput {
    /* position: relative;
    display: block; */
}

div.compSearchTextInput ul {
    background-color: #fff;
    /* position: absolute; */
    /* z-index: 10; */
    list-style: none;
    padding: 0;
    /* border-top: 1px solid #aaa; */
    /* margin-top: -6px; */
    /* margin-left: 4px; */
    box-shadow: 2px 4px 4px 0 rgba(0,0,0,0.16) /*, 2px 4px 10px 0 rgba(0,0,0,0.12); */
}

div.compSearchTextInput ul li {
    padding: 3px;
    border: 1px solid #aaa;
    border-top: none;
    cursor: pointer;
}


div.compSearchTextInput ul li.selected {
    background-color: #337ab7;
    color: #fff;
}

/* SideMenu */

div.side-menu {
    -webkit-box-shadow: -5px 0px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -5px 0px 10px 0px rgba(0,0,0,0.75);
    box-shadow: -5px 0px 10px 0px rgba(0,0,0,0.75);
    padding: 12px;
    background-color: #fff;
    z-index: 100;
    position: fixed;
    right: 0;
}
  
div.letter-side-menu h1 {
    margin-top: 0;
    margin-bottom: 0.2em;
    font-size: 3em;
}
  
div.letter-side-menu input[type="text"] {
    font-size: 2em;
    margin-left: 20px;
}
  
div.letter-side-menu input[type="text"]::placeholder {
    font-size: 0.7em;
}

div.letter-side-menu div.NumberPicker input {
    font-size: 1.2em;
    color: black;
}

div.side-menu.settings-side-menu {
    top: 0;
    height: 100vh;
    overflow: scroll;
}

div.settings-side-menu div.input {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 20px;
}

div.settings-side-menu hr {
    width: 100%;
}

div.settings-side-menu h1 {
    margin-top: 0;
}

div.settings-side-menu input,
div.settings-side-menu select {
    font-size: 20px;
}

/* TargetDimsPicker */ 

div.compTargetDimsPicker .customDims label {
    margin-top: 4px;
}

div.compTargetDimsPicker .customDims input[type='text'] {
    background-color: inherit;
    color: inherit;
    text-align: right;
    width: 3em;
}

/* UndoRedo */

div.compUndoRedo {
    flex-direction: row;
}

div.compUndoRedo img {
    cursor: pointer;
}
html, body, div#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px; /* Needs to match DEFAULT_FONT_SIZE in ChartToImage.tsx */
}

div {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.flex-row {
  flex-direction: row;
  justify-content: space-between;
}

.flex-row-left {
  flex-direction: row;
  justify-content: start;
}

div#page-root {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

a, a:visited {
  color: #1892ef;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  background-color: #1892ef;
  border: none;
  font-size: 16px;
  margin: 4px;
  border-radius: 8px;
  padding: 8px;
  color: white;
  cursor: pointer;
  align-self: flex-start;
}

button:disabled {
  background-color: #1892ef99;
  cursor: not-allowed;
}

button:focus {
  outline: none;
  /* border: 2px solid #1274bf; */
}

button:active {
  background-color: #1892efdd;
}

button.red {
  background-color: #c9302c;
}

button.red:disabled {
  background-color: #c9302c99;
}

button.red:active {
  background-color: #c9302cdd;
}

button.mini {
  font-size: 12px;
  padding: 4px;
  border-radius: 4px;
}

button.big {
  font-size: 20px;
  padding: 12px;
  align-self: stretch;
}

label {
  font-weight: 200;
  margin-right: 8px;
}

ul {
  margin: 0;
  padding: 0;
}

ul.list-row > li {
  display: inline-block;
  margin: 0 8px;
}

li {
  list-style: none;
}

ul.std {
  font-size: 1.3em;
}

.std-h {
  margin-bottom: 0.2em;
}

.indent {
  padding-left: 16px;
}

.space-kids > * {
  margin-bottom: 16px;
}

.h-pad {
  padding-left: 8px;
  padding-right: 8px;
}

hr {
  border-color: #ddd;
  width: 80%;
  margin-left: 0;
}

/******** 
      TABLES
 *******/

table {
  border-collapse: collapse;
}

td {
  border: 1px #ddd solid;
}

table.padded-cells tr td {
  padding: 0.5em 1em;
}

table.menu tr td:hover {
  cursor: pointer;
  color: #1892ef;
}

/*********
    FORM ELEMENTS 
********/

input[type=text], 
input[type=password] {
  border: none;
  border-bottom: 1px #ddd solid;
  padding: 1px 4px;
  font-size: 16px;
}

input[type=text]:focus, 
input[type=password]:focus {
  outline: none;
  border-bottom-color: #1892ef;
}

input:disabled {
  color: #aaa;
  -webkit-text-fill-color: #aaa;
  opacity: 1;
  background: none;
}

.error {
  color: #c9302c;
}

.flex-space {
  flex-grow: 1;
}
